.wrapper {
    display: none;
    list-style: none;
    font-size: 10rem;
    padding: 0;
}

.crumb {
    text-transform: capitalize;
    font-size: 13rem;
}

.crumb:not(:last-child)::after {
    content: '/';
    margin: 0 6px;
}

@media screen and (min-width: 601px) {
    .wrapper {
        display: flex;
    }
}

@media screen and (min-width: 800px) {
    .wrapper {
        font-size: 14rem;
    }
}